/* You can add global styles to this file, and also import other style files */


/* CSS for Loading Spinner, since it is not included in Argon */

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: rotate 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
}


/************************************************************************/