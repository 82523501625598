//
// Avatar
//

// General styles

.avatar {
	color: $white;
	background-color: $gray-500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	border-radius: 50%;
	height: 48px;
	width: 48px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	+ .avatar-content {
		display: inline-block;
		margin-left: 0.75rem;
	}
}

// Avatar size variations

.avatar-lg {
	width: 58px;
	height: 58px;
	font-size: $font-size-sm;
}

.avatar-sm {
	width: 36px;
	height: 36px;
	font-size: $font-size-sm;
}
